/*eslint max-lines-per-function: ["error", 180]*/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from 'react-bootstrap';

import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';
import { FormalIrregulationsType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

type Props = {
  data: FormalIrregulationsType[] | undefined;
  dataCSV: unknown[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportByFormalIrregulationsType: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = (data && data.length > 0) || false;

  const headers1 = [
    {
      key: 'entityName',
      label: 'Strona(podmiot)',
    },
    {
      key: 'productDescription',
      label: 'Wyrób',
    },
  ];
  const headers2 = [
    {
      key: 'startDate',
      label: 'Data rozpoczęcia',
    },
    {
      key: 'signatureDate',
      label: 'Data podpisania protokołu kontroli',
    },
  ];

  const headers3 = [
    {
      key: 'unitSymbol',
      label: 'Organ',
    },
    {
      key: 'fileNumber',
      label: 'Nr akt',
    },
    {
      key: 'formalIrregulations',
      label: 'Nieprawidłowości formalne (rodzaje)',
    },
  ];

  const headers4 = [
    {
      key: 'remedialProvisionBase',
      label: 'Podstawa',
    },
    {
      key: 'remedialProvisionUnit',
      label: 'Organ',
    },
    {
      key: 'remedialProvisionDate',
      label: 'Data wydania',
    },
    {
      key: 'remedialProvisionSign',
      label: 'Znak',
    },
    {
      key: 'remedialProvisionComplaint',
      label: 'Zażalenie',
    },
    {
      key: 'remedialProvisionComplaintResult',
      label: 'Wynik zażalenia',
    },
  ];

  const headers5 = [
    {
      key: 'decisionBase',
      label: 'Podstawa',
    },
    {
      key: 'decisionUnit',
      label: 'Organ',
    },
    {
      key: 'decisionDate',
      label: 'Data wydania',
    },
    {
      key: 'decisionSign',
      label: 'Znak',
    },
    {
      key: 'decisionAppeal',
      label: 'Odwołanie',
    },
    {
      key: 'decisionAppealResult',
      label: 'Wynik odwołania',
    },
  ];

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table
          id="table-to-export"
          className="table-with-overflow"
          striped
          bordered
          hover
        >
          <thead className="text-center">
            <tr>
              {headers1.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
              <th colSpan={2}>Data kontroli</th>
              {headers3.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
              <th colSpan={6}>Postanowienie naprawcze</th>
              <th colSpan={6}>Decyzja</th>
            </tr>
            <tr>
              {headers2.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
              {headers4.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
              {headers5.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {data.map(pub => (
                <GetDataRow key={uuidv4()} pub={pub} />
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const GetDataRow: React.FC<{
  pub: FormalIrregulationsType;
}> = ({ pub }) => {
  if (!pub) {
    return <React.Fragment key={uuidv4()}></React.Fragment>;
  }

  return (
    <React.Fragment key={uuidv4()}>
      <tr>
        <td>{pub.entityName}</td>
        <td>{pub.productDescription}</td>
        <td>{pub.startDate}</td>
        <td>{pub.signatureDate}</td>
        <td>{pub.unitSymbol}</td>
        <td>
          {pub.inspectionId && pub.fileNumber && (
            <a href={'/kontrole/podglad/' + pub.inspectionId}>
              {pub.fileNumber}
            </a>
          )}
        </td>
        <td>{pub.formalIrregulations}</td>
        <td>{pub.remedialProvisionBase}</td>
        <td>{pub.remedialProvisionUnit}</td>
        <td>{pub.remedialProvisionDate}</td>
        <td>
          {pub.procedureId && pub.remedialProvisionSign && (
            <a href={'/postepowania/podglad/' + pub.procedureId}>
              {pub.remedialProvisionSign}
            </a>
          )}
        </td>
        <td>{pub.remedialProvisionComplaint}</td>
        <td>{pub.remedialProvisionComplaintResult}</td>
        <td>{pub.decisionBase}</td>
        <td>{pub.decisionUnit}</td>
        <td>{pub.decisionDate}</td>
        <td>
          {pub.procedureId && pub.decisionSign && (
            <a href={'/postepowania/podglad/' + pub.procedureId}>
              {pub.decisionSign}
            </a>
          )}
        </td>
        <td>{pub.decisionAppeal}</td>
        <td>{pub.decisionAppealResult}</td>
      </tr>
    </React.Fragment>
  );
};
