import { GetTechAssessmentDto } from '../autogenerated/snrwbApiClient/models/GetTechAssessmentDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateTechAssessmentDto,
  CreateUpdateTechAssessmentDtoFromJSON,
  CreateUpdateOrganizationDto,
} from '../autogenerated/snrwbApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { TechAssessmentSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { techAssessmentForGrid } from '../../../snrwb/components/TechAssessments/TechAssessments';
import momencik from '../../momencik';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import { SnrwbCoreContextType } from '..';
import OrganizationalUnitBadge from '../../../snrwb/components/OrganizationalUnit/OrganizationalUnitBadge';

export type TechAssessmentDto =
  | GetTechAssessmentDto
  | CreateUpdateTechAssessmentDto;

export interface TechAssessmentContextInterface {
  getById: (id: string) => Promise<GetTechAssessmentDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getByOrganization: (id: string) => Promise<GetTechAssessmentDto[]>;
  create: (dto: CreateUpdateTechAssessmentDto) => Promise<GetTechAssessmentDto>;
  createWithOrganization: (
    techAssessment: CreateUpdateTechAssessmentDto,
    organization: CreateUpdateOrganizationDto,
  ) => Promise<GetTechAssessmentDto>;
  update: (id: string, dto: CreateUpdateTechAssessmentDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const TechAssessmentContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.techAssessmentControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.techAssessmentControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(techAssessmentForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.techAssessmentControllerGetAllCount(filterText || ''),

    getByOrganization: (id: string) =>
      api
        .techAssessmentControllerGetByOrganization(id)
        .then(cert =>
          cert.sort((a, b) => a.number?.localeCompare(b.number, 'pl')),
        ),
    create: (techAssessment: CreateUpdateTechAssessmentDto) =>
      api.techAssessmentControllerCreate(techAssessment),
    createWithOrganization: (
      techAssessment: CreateUpdateTechAssessmentDto,
      organization: CreateUpdateOrganizationDto,
    ) =>
      api.techAssessmentControllerCreateWithOrganization({
        techAssessment: techAssessment,
        organization: organization,
      }),
    update: (id: string, techAssessment: CreateUpdateTechAssessmentDto) =>
      api.techAssessmentControllerUpdate(id, techAssessment),
    delete: (id: string) => api.techAssessmentControllerDelete(id),
  };
};

export const validate = (techAssessment: CreateUpdateTechAssessmentDto) =>
  validateAgainst(TechAssessmentSchema, techAssessment);

export const newTechAssessment = () => {
  return CreateUpdateTechAssessmentDtoFromJSON({});
};

export const convert = (techAssessment: GetTechAssessmentDto) => {
  if (!techAssessment) {
    return newTechAssessment();
  }
  const converted = CreateUpdateTechAssessmentDtoFromJSON(techAssessment);
  converted.organizationalUnitId = techAssessment.organizationalUnit?.id;
  converted.producerId = techAssessment.producer?.id;
  converted.groupProductTypeId = techAssessment.groupProductType?.id;

  return converted;
};

export const forPresentation = (techAssessment: GetTechAssessmentDto) => {
  const form = [];
  if (techAssessment.producer) {
    form.push({
      name: 'Producent',
      value: techAssessment.producer.name,
    });
  }
  if (techAssessment.date) {
    form.push({
      name: 'Data',
      value: momencik(techAssessment.date),
    });
  }
  if (techAssessment.number) {
    form.push({
      name: 'Numer',
      value: techAssessment.number,
    });
  }
  if (techAssessment.groupProductType) {
    form.push({
      name: 'Grupa wyrobu',
      value: DictionaryStatic({ value: techAssessment.groupProductType }),
    });
  }
  if (techAssessment.organizationalUnit) {
    form.push({
      name: 'Jednostka notyfikowana',
      value: OrganizationalUnitBadge({
        organizationalUnit: techAssessment.organizationalUnit,
      }),
    });
  }
  if (techAssessment.note) {
    form.push({
      name: 'Uwagi',
      value: techAssessment.note,
    });
  }

  return form;
};

const addDictionaryHistoryEntry = async (
  snrwb: SnrwbCoreContextType,
  name: string,
  dictionaryId: string,
  prevDictionaryId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any[],
) => {
  const dict = DictionaryStatic({
    value: await snrwb.dictionaries.getById(dictionaryId),
  });

  let prevDict = undefined;

  if (prevDictionaryId) {
    prevDict = DictionaryStatic({
      value: await snrwb.dictionaries.getById(prevDictionaryId),
    });
  }
  form.push({
    name: name,
    value: dict,
    prevValue: prevDict,
    equal: dictionaryId === prevDictionaryId,
  });
};

export const forPresentationHistory = async (
  snrwb: SnrwbCoreContextType,
  techAssessment: CreateUpdateTechAssessmentDto,
  prevTechAssessment: CreateUpdateTechAssessmentDto,
) => {
  const form = [];

  if (techAssessment.date) {
    form.push({
      name: 'Data',
      value: momencik(techAssessment.date),
      prevValue: momencik(prevTechAssessment?.date),
      equal: techAssessment.date === prevTechAssessment?.date,
    });
  }
  form.push({
    name: 'Numer',
    value: techAssessment.number,
    prevValue: prevTechAssessment?.number,
    equal: techAssessment.number === prevTechAssessment?.number,
  });

  await addDictionaryHistoryEntry(
    snrwb,
    'Grupa wyrobu',
    techAssessment?.groupProductTypeId,
    prevTechAssessment?.groupProductTypeId,
    form,
  );

  form.push({
    name: 'Jednostka notyfikowana',
    value: OrganizationalUnitBadge({
      organizationalUnit: await snrwb.organizationalUnits.getById(
        techAssessment.organizationalUnitId,
      ),
    }),
    prevValue: prevTechAssessment
      ? OrganizationalUnitBadge({
          organizationalUnit: await snrwb.organizationalUnits.getById(
            prevTechAssessment.organizationalUnitId,
          ),
        })
      : undefined,
    equal:
      techAssessment.organizationalUnitId ===
      prevTechAssessment?.organizationalUnitId,
  });

  form.push({
    name: 'Uwagi',
    value: techAssessment.note,
    prevValue: prevTechAssessment?.note,
    equal: techAssessment.note === prevTechAssessment?.note,
  });

  return form;
};
