import React from 'react';
import {
  Badge,
  Card,
  Tab,
  Button,
  Alert,
  Form,
  TabPane,
} from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import {
  GetInspectionDto,
  GetInspectedProductDto,
  GetAttachmentDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetInspectorDto,
  CreateUpdateAttachmentDto,
  CreateUpdateMisstatementDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  CreateUpdateInspectorDto,
  GetProceedingDto,
  GetPakDto,
  CreateUpdateInspectionDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import RichTextEdit from '../../../app/components/RichTextEdit';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { InspectionApi } from '../../../common/snrwbCore/contexts/InspectionContext';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import { SubjectMemos } from '../Memos/SubjectMemos';

import { InspectionProtocols } from './InspectionProtocols';
import { InspectionMetricEdit } from './InspectionMetricEdit';
import { InspectionSigning } from './InspectionSigning';
import { InspectedProducts } from './InspectedProducts';

interface InspectionTabsProps {
  editAuthorized: boolean;
  readonly: boolean;
  validOrganizationalUnit: boolean;
  inspection: GetInspectionDto;
  inspectionHeader: string;
  banner?: { inspectedProductId: string; bannerId: string };
  setBanner: (
    b: { inspectedProductId: string; bannerId: string } | undefined,
  ) => void;
  products?: GetInspectedProductDto[];
  changeRichText: (obj: Partial<CreateUpdateInspectionDto>) => void;
  proceedings?: GetProceedingDto[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  misstatements: AssociateDocuments<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  protocols: AssociateDocuments<
    ProtocolDuringInspectionContext.ProtocolWithAttachments,
    ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
  >;
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  paks: GetPakDto[];
  activeProduct?: string;
  errors: string[];
  api: InspectionApi;
}

export const InspectionTabs: React.FC<InspectionTabsProps> = props => {
  return (
    <Card>
      <Card.Header>
        <div className="d-flex">
          <span className="flex-fill align-self-center">
            Kontrola{' '}
            {props.inspection.cpr && (
              <>
                <Badge bg="warning" text="dark">
                  CPR
                </Badge>{' '}
              </>
            )}
            {props.inspectionHeader}
          </span>
          <div className="ms-1">
            <SubjectMemos subject={props.inspection} />
          </div>
          <div className="ms-1">
            <Button
              variant="outline-primary"
              onClick={props.api.inspectionProtocol}
              className="h-100"
            >
              Protokół
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {props.inspection.metric &&
        !props.inspection.metric.approved &&
        !props.validOrganizationalUnit ? (
          <Alert variant="warning" className="mb-0">
            <Alert.Heading>Kontrola ma niezatwierdzoną metrykę</Alert.Heading>
            Jest widoczna wyłącznie dla pracowników{' '}
            <OrganizationalUnitBadge
              organizationalUnit={props.inspection.metric.organizationalUnit}
            />
          </Alert>
        ) : (
          <>
            {props.inspection.id && !props.editAuthorized && (
              <Alert variant="warning">
                Brak uprawnień do edycji
                {!props.validOrganizationalUnit && ' (niewłaściwy organ)'}
              </Alert>
            )}
            <Card className="mt-3">
              <Tabs
                defaultActiveKey={props.banner ? 'products' : 'metric'}
                id="kontrola"
                className="mb-3"
              >
                <Tab eventKey="metric" title="Metryka" className="m-3">
                  <InspectionMetricEdit {...props} />
                </Tab>
                <TabPane
                  eventKey="products"
                  title="Wyroby"
                  className="m-3"
                  mountOnEnter={false}
                  unmountOnExit={false}
                >
                  <InspectedProducts
                    {...props}
                    banner={props.banner}
                    onBannerClosed={() => props.setBanner(undefined)}
                  />
                </TabPane>
                <Tab
                  eventKey="attachments"
                  title="Wykaz załączników"
                  className="m-3"
                >
                  <RichTextEdit
                    id={'InspectionAttachments_' + props.inspection.id}
                    value={props.inspection.attachments}
                    readonly={props.readonly}
                    onChange={value =>
                      props.changeRichText({ attachments: value })
                    }
                  />
                </Tab>
                <Tab
                  eventKey="protocols"
                  title="Wykaz protokołów"
                  className="m-3"
                >
                  <InspectionProtocols
                    inspection={props.inspection}
                    products={props.products}
                    protocols={props.protocols}
                    readonly={props.readonly}
                    changeRichText={props.changeRichText}
                  />
                </Tab>
                <Tab eventKey="findings" title="Ustalenia" className="mx-3">
                  <Form className="d-grid gap-3 mb-3">
                    <Form.Group controlId="findingsBeginning">
                      <Form.Label className="col-form-label fw-bold">
                        Wstęp do ustaleń
                      </Form.Label>
                      <RichTextEdit
                        className="narrow"
                        value={props.inspection.findingsBeginning}
                        readonly={props.readonly}
                        onChange={value =>
                          props.changeRichText({ findingsBeginning: value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="findingsEnd">
                      <Form.Label className="col-form-label fw-bold">
                        Podsumowanie ustaleń
                      </Form.Label>
                      <RichTextEdit
                        className="narrow"
                        value={props.inspection.findingsEnd}
                        readonly={props.readonly}
                        onChange={value =>
                          props.changeRichText({ findingsEnd: value })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Tab>
                <Tab eventKey="protocol" title="Protokół" className="m-3">
                  <InspectionSigning
                    editAuthorized={props.editAuthorized}
                    inspection={props.inspection}
                    inspectors={props.inspectors}
                    onChange={props.api.editInspection}
                    onProtocol={props.api.inspectionProtocol}
                    readyToSign={props.api.readyToSign}
                    sign={props.api.sign}
                    revertSign={props.api.revertSign}
                  />
                </Tab>
              </Tabs>
            </Card>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
