import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { CSVLink } from 'react-csv';
import { Button } from 'react-bootstrap';

import momencik from '../../momencik';
import { useAuth } from '../../hooks/useAuth';
import {
  FilterReportData,
  FilterReportYearData,
} from '../../../snrwb/components/Reports/Filter';
import { useSnrwbCore } from '../../hooks/useSnrwbCore';
import { Abbreviation } from '../../abbreviationLegend';
import parseParagraphData from '../../parseParagraphData';

type ExportToExcelProps = {
  fileName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csvReport?: any;
  filter?: FilterReportData | FilterReportYearData;
  idName?: string;
  legend?: Abbreviation[];
};

export const ExportToExcel: React.FC<ExportToExcelProps> = ({
  fileName,
  csvReport,
  filter,
  children,
  idName,
  legend,
}) => {
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const tableToExport = idName ? idName : 'table-to-export';

  /*eslint complexity: ["error", 13]*/
  const stringifyFilterData = async (
    filterData: FilterReportData | FilterReportYearData | undefined,
  ): Promise<string> => {
    let resultText = '';

    if (filterData) {
      if ('dateFrom' in filterData && filterData.dateFrom) {
        resultText += `data od: ${momencik(filterData.dateFrom) || '-'}, `;
      }
      if ('dateTo' in filterData && filterData.dateTo) {
        resultText += `data do: ${momencik(filterData.dateTo) || '-'}, `;
      }
      if ('newYear' in filterData && filterData.newYear) {
        resultText += `rok: ${filterData.newYear || '-'}, `;
      }
    }

    const organizationalName =
      (filterData?.organizationalUnitId &&
        (
          await snrwb.organizationalUnits.getById(
            filterData?.organizationalUnitId,
          )
        )?.name) ||
      'wszystkie';

    resultText += `organ: ${organizationalName}`;

    return resultText;
  };

  const exportToExcel = async () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById(tableToExport),
      {
        raw: true,
      },
    );

    const classNameToExclude = 'no-date-parsing';
    const classNameForNumbers = 'numbers-column';
    const classNameForPercentage = 'percentage-column';
    const classNameForCosts = 'costs-column';
    const classNameForHtml = 'html-column';

    Object.keys(worksheet).forEach(cellRef => {
      const { r, c } = XLSX.utils.decode_cell(cellRef);
      const cell = worksheet[cellRef];

      if (cell.v && cell.v.toString().trim() !== '' && c !== undefined) {
        const tableCell = document
          .getElementById(tableToExport)
          ?.getElementsByTagName('tr')
          [r]?.getElementsByTagName('td')[c];

        const classList = tableCell?.classList;

        if (classList?.contains(classNameToExclude)) {
          const columnRef = XLSX.utils.encode_col(c);
          Object.keys(worksheet).forEach(cellRef => {
            if (cellRef.startsWith(columnRef)) {
              const cell = worksheet[cellRef];
              cell.z = '@';
            }
          });
        }

        if (classList?.contains(classNameForNumbers)) {
          cell.t = 'n';
          const roundedValue = Math.round(cell.v);
          const isWholeNumber =
            Math.abs(cell.v - roundedValue) < Number.EPSILON;
          if (isWholeNumber) {
            cell.z = '0';
          } else {
            cell.z = '#,##0.00';
          }
        }

        if (classList?.contains(classNameForCosts)) {
          cell.t = 'n';
          cell.z = '#,##0.00';
        }

        if (classList?.contains(classNameForPercentage) && cell.v) {
          cell.v = (parseFloat(cell.v.replace('%', '')) / 100).toFixed(4);
          cell.t = 'n';
          cell.z = '0.00%';
        }

        if (classList?.contains(classNameForHtml)) {
          const newValue = parseParagraphData(tableCell?.innerHTML);
          if (newValue) {
            cell.v = newValue;
          }
        }
      }
    });

    const lastLine = [
      [],
      [],
      [
        `Drukowane w SNRWB dnia ${momencik(new Date(), 'LLLL')} przez ${
          auth.currentUser?.name || ''
        }`,
      ],
      [`Filtr: ${await stringifyFilterData(filter)}`],
    ];

    const legendLines = legend
      ? [[], ...legend.map(item => [`${item.key}: ${item.value}`])]
      : [];

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    XLSX.utils.sheet_add_aoa(worksheet, lastLine, { origin: -1 });

    XLSX.utils.sheet_add_aoa(worksheet, legendLines, { origin: -1 });

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const dataBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(dataBlob, `${fileName || 'report'}.xlsx`);
  };

  return (
    <div id={tableToExport}>
      {children}
      <Button className="btn btn-secondary me-2" onClick={exportToExcel}>
        Eksport do XLSX
      </Button>
      {csvReport && (
        <CSVLink separator=";" {...csvReport}>
          <Button variant="secondary">Eksport do CSV</Button>
        </CSVLink>
      )}
    </div>
  );
};
