import { OrgUnitInspectedProductsRanges } from '../snrwbCore/autogenerated/snrwbApiClient';

export type ReportInspected = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: number;
    };
  };
  sum: {
    [dictShortname: string]: number;
  };
};

export type ReportInspectedOrganizations = {
  details: {
    [orgUnitShortname: string]: {
      [organizationType: string]: {
        [dictShortname: string]: number;
      };
    };
  };
  sum: {
    [organizationType: string]: {
      [dictShortname: string]: number;
    };
  };
};

export type ReportProductsByMisstatement = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: {
        [misstatement: string]: number;
      };
    };
  };
  sum: {
    [dictShortname: string]: {
      [misstatement: string]: number;
    };
  };
};

export type ReportInspectedProductsByRangeType = {
  details: { [orgUnitShortname: string]: OrgUnitInspectedProductsRanges };
  sum?: OrgUnitInspectedProductsRanges;
};

export type DifferentCountForDictionaryShortname = {
  [dictShortname: string]: {
    [signON: string]: number;
  };
};

export type ReportInspectedProductsBySignType = {
  details: {
    [orgUnitShortname: string]: DifferentCountForDictionaryShortname;
  };
  sum: DifferentCountForDictionaryShortname;
};

export type ReportInspectedProductsByTypeType = {
  reportName: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data: any[];
};

export const OrderedSampleExamsKinds = [
  'positive',
  'negative',
  'count',
] as const;

export type OrderedSampleExamsKindsKeys =
  typeof OrderedSampleExamsKinds[number];

export type OrderedSampleExamsDetails = Record<
  OrderedSampleExamsKindsKeys,
  number
>;

export type ReportOrderedSampleExamsType = {
  details: {
    [orgUnitShortname: string]: {
      [collectMode: string]: {
        [organizationType: string]: OrderedSampleExamsDetails;
      };
    };
  };

  sum: {
    [collectMode: string]: {
      [organizationType: string]: OrderedSampleExamsDetails;
    };
  };
};

export const CountCprDetailsKinds = ['cpr', 'all'] as const;

export type CountCprDetailsKindsKeys = typeof CountCprDetailsKinds[number];

export type CountCprDetails = Record<CountCprDetailsKindsKeys, number>;

export type ReportJudgmentsCountType = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: CountCprDetails;
    };
  };
  sum: {
    [dictShortname: string]: CountCprDetails;
  };
};

export const CorrectiveActionsDetailsKinds = [
  'countAfterProceeding',
  'countInInspection',
  'countBetweenInspProc',
  'countArt3011',
  'countArt3012',
  'count',
  'countAfterProceedingCpr',
  'countInInspectionCpr',
  'countBetweenInspProcCpr',
  'countArt3011Cpr',
  'countArt3012Cpr',
  'countCpr',
] as const;

export type CorrectiveActionsDetailsKindsKeys =
  typeof CorrectiveActionsDetailsKinds[number];

export type CorrectiveActionsDetails = Record<
  CorrectiveActionsDetailsKindsKeys,
  number
>;

export type ReportCorrectiveActionsType = {
  details: {
    [orgUnitShortname: string]: CorrectiveActionsDetails;
  };
  sum: CorrectiveActionsDetails;
};

export type ReportPakJudgmentType = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: number;
    };
  };
  sum: {
    [dictShortname: string]: number;
  };
};

export const PakAmountDetailsCounts = [
  'count',
  'sumImposedCashPenalties',
  'countOfCancellations',
  'sumPaid',
] as const;

export type PakAmountDetailsCountsKeys = typeof PakAmountDetailsCounts[number];

export type PakAmountDetails = Record<PakAmountDetailsCountsKeys, number>;

export type ReportPakAmountType = {
  details: {
    [orgUnitShortname: string]: PakAmountDetails;
  };
  sum: PakAmountDetails;
};

export const ProceedingDetailsCounts = [
  'count',
  'countStartBeforeEndIn',
  'countStartInEndIn',
  'countStartInEndAfter',
] as const;

export type ProceedingDetailsCountsKeys =
  typeof ProceedingDetailsCounts[number];

export type ProceedingDetails = Record<ProceedingDetailsCountsKeys, number>;

export type ReportProceedingType = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: ProceedingDetails;
    };
  };
  sum: {
    [dictShortname: string]: ProceedingDetails;
  };
};

export const ProductsGroupsDetailsCounts = [
  'countInspectedProducts',
  'countMisstatement',
  'countSampleCommision',
  'countSampleNegative',
] as const;

export type ProductsGroupsDetailsCountsKeys =
  typeof ProductsGroupsDetailsCounts[number];

export type ProductsGroupsDetails = Record<
  ProductsGroupsDetailsCountsKeys,
  number
>;

export type ReportProductsGroupsType = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: ProductsGroupsDetails;
    };
  };
  sum: {
    [dictShortname: string]: ProductsGroupsDetails;
  };
};

export const CostSampleExamsDetailsSum = ['sumCpr', 'sumWithoutCpr'] as const;

export type CostSampleExamsKeys = typeof CostSampleExamsDetailsSum[number];

export type CostSampleExamsDetails = Record<CostSampleExamsKeys, number>;

export type ReportCostSampleExamsType = {
  details: {
    [orgUnitShortname: string]: {
      [dictShortname: string]: CostSampleExamsDetails;
    };
  };
  sum: {
    [dictShortname: string]: CostSampleExamsDetails;
  };
};

export type InspectionPlanForOrganizationDetails = {
  inspectionPlannedCount: number;
  inspectionPlannedCprCount: number;
  inspPlanProductCount: number;
  inspPlanProductCPRCount: number;
  inspectionAdHocCount: number;
  inspectionAllCount: number;
};

export type ReportInspectionPlanForOrganizationType = {
  details: {
    [orgUnitShortname: string]: InspectionPlanForOrganizationDetails;
  };
  sum: InspectionPlanForOrganizationDetails;
};

export type InspectionPlanForInspectedProductDetails = {
  inspProdPlannedCount: number;
  inspProdPlannedCPRCount: number;
  inspPlanProductCount: number;
  inspPlanProductCPRCount: number;
};

export type ReportInspectionPlanForInspectedProductType = {
  details: {
    [orgUnitShortname: string]: InspectionPlanForInspectedProductDetails;
  };
  sum: InspectionPlanForInspectedProductDetails;
};

export type EssentialFutureType = {
  feature: string;
  functionalProperty: string;
  examMethod: string;
  result: boolean;
  controlResult: boolean;
  expectedResultValue: string;
  resultValue: string;
  resultUncertainty: string;
};

export type ReportPublicationsType = {
  controlSample: boolean | null;
  publicationNumber: string;
  operateOrgUnitShortname: string;
  examOrgUnitShortname: string;
  collectDate: Date | null;
  collectPlace: string | null;
  groupShortname: string | null;
  organization: string | null;
  nkitw: string | null;
  description: string | null;
  collectBasis: string | null;
  subGroupShortname: string | null;
  examCostForGoverment: string | null;
  transportCostForGoverment: string | null;
  storageCostForGoverment: string | null;
  examCostForInspected: string | null;
  transportCostForInspected: string | null;
  storageCostForInspected: string | null;
  collectDateString: string | null;
  technicalSpecification: string | null;
  negative: boolean | null;
  essentialFuture: EssentialFutureType[] | null;
};

export type JudgmentDetails = {
  judgmentType: string;
  judgmentOrganizationalUnit: string;
  judgmentPublishDate: Date;
  judgmentSign: string;
  judgmentAppeal: boolean;
  judgmentAppealResult: string;
};

export type ReportPakAnalysisMoneyPenaltiesType = {
  pakId: number;
  entityName: string;
  productDescription: string;
  unitSymbol: string;
  penaltyAmount: number;
  judgments: JudgmentDetails[] | null;
};

export type JudgmentSampleDetails = {
  type: string;
  organizationalUnit: string;
  issueDate: Date;
  sign: string;
  appeal: boolean;
  appealResult: string;
};

export type ReportControlSampleAnalysisType = {
  sampleCollectId: string;
  entityName: string; // 1
  productDescription: string; // 2
  activityDate: Date; //3
  protocolSigningDate: Date; //4
  unitSymbol: string; //5
  recordNumber: string; //6
  storageCondition: string; // 7
  judgments: JudgmentSampleDetails[] | null;
};

export type ReportNegativeTest = {
  entityName: string;
  product: string;
  pprDateActivity: Date;
  pprDateSigningProtocol: Date;
  jorSymbol: string;
  pprFileNo: string;
  pprId: string;
  characteristics: ReportNegativeCharacteristic[];
  judgments: ReportNegativeJudgmentSampleDetails[];
};

export type ReportNegativeCharacteristic = {
  functionalProperties: string;
  declaredValue: string;
  testResult: string;
  declaredCheckValue: string;
};

export type ReportNegativeJudgmentSampleDetails = {
  type: string;
  organizationalUnit: string;
  description: string;
  sign: string;
  appeal: string;
  appealResult: string;
};
