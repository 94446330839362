/*eslint max-lines-per-function: ["error", 230]*/

import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import MultiColumnValue from '../../../app/components/MultiColumnValue';
import {
  CreateUpdateSampleDto,
  GetOrganizationalUnitDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, { FormRow66 } from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import TextOrControl from '../../../app/components/TextOrControl';
import momencik from '../../../common/momencik';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';
import DictionarySelector from '../Selects/DictionarySelector';

export default ({
  presentedSample,
  editAuthorized,
  setProperty,
  presentedToSelected,
  changeAndBlur,
  sampleChange,
  operateOrganizationalUnit,
  notifiedUnit,
  collectPlace,
  examOrganizationalUnitName,
  storagePlaceId,
}: {
  presentedSample: CreateUpdateSampleDto;
  editAuthorized: boolean;
  setProperty: (obj: Partial<CreateUpdateSampleDto>) => void;
  presentedToSelected: () => void;
  changeAndBlur: (obj: Partial<CreateUpdateSampleDto>) => void;
  sampleChange: (obj: Partial<CreateUpdateSampleDto>) => void;
  operateOrganizationalUnit: GetOrganizationalUnitDto;
  notifiedUnit: GetOrganizationalUnitDto;
  collectPlace: string;
  examOrganizationalUnitName: string;
  storagePlaceId: string | null;
}) => {
  const [storagePlaceText, setStoragePlaceText] = useState<string>('');
  useEffect(() => {
    if (!storagePlaceId) {
      return;
    }
    if (storagePlaceId === '5eae28a5-ab41-4686-be60-82284dac0441') {
      setStoragePlaceText(collectPlace);
    } else if (storagePlaceId === '769792ff-cf80-479a-8180-3e6243666e3b') {
      setStoragePlaceText(examOrganizationalUnitName);
    }
  }, [collectPlace, examOrganizationalUnitName, storagePlaceId]);

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="quantity" label="Pobrana ilość">
          <TextOrControl
            readonly={!editAuthorized}
            value={presentedSample.quantity}
          >
            <RichTextSingleLine
              value={presentedSample.quantity || ''}
              onChange={v => changeAndBlur({ quantity: v })}
              onlyIndexes={true}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="batchNumber" label="Numer partii">
          <TextOrControl
            readonly={!editAuthorized}
            value={presentedSample.batchNumber}
          >
            <Form.Control
              type="text"
              value={presentedSample.batchNumber || ''}
              onChange={e => setProperty({ batchNumber: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="collectionMethod" label="Opis sposobu pobrania">
          <TextOrControl
            readonly={!editAuthorized}
            value={presentedSample.collectionMethod}
          >
            <DynamicTextarea
              value={presentedSample.collectionMethod || ''}
              onChange={e => setProperty({ collectionMethod: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <Row>
          <Col md={6}>
            <FormRow66
              controlId="productionDate"
              label="Data produkcji"
              static={!editAuthorized}
            >
              <TextOrControl
                readonly={!editAuthorized}
                value={momencik(presentedSample.productionDate)}
              >
                <MultiColumnValue
                  variant="outline-secondary"
                  onChange={sampleChange}
                  columns={[
                    {
                      label: 'Data',
                      type: 'Date',
                      key: 'productionDate',
                      value: presentedSample.productionDate || null,
                    },
                    {
                      label: 'Opis',
                      type: 'string',
                      key: 'productionDateText',
                      value: presentedSample.productionDateText || null,
                    },
                  ]}
                />
              </TextOrControl>
            </FormRow66>
          </Col>
          <Col md={6}>
            <FormRow66
              controlId="operateOrganizationalUnit"
              label="Organ obsługujący"
            >
              {!editAuthorized ? (
                <OrganizationalUnitBadge
                  organizationalUnit={operateOrganizationalUnit}
                />
              ) : (
                <OrganizationalUnitSelector
                  option={OrganizationalUnitsGetOptions.notNotified}
                  value={presentedSample.operateOrganizationalUnitId}
                  onChange={option => {
                    sampleChange({ operateOrganizationalUnitId: option.value });
                  }}
                />
              )}
            </FormRow66>
            <FormRow66 controlId="notifiedUnit" label="Jednostka notyfikowana">
              {!editAuthorized ? (
                <OrganizationalUnitBadge organizationalUnit={notifiedUnit} />
              ) : (
                <OrganizationalUnitSelector
                  option={OrganizationalUnitsGetOptions.notified}
                  value={presentedSample.notifiedUnitId}
                  onChange={option => {
                    sampleChange({ notifiedUnitId: option.value });
                  }}
                />
              )}
            </FormRow66>
          </Col>
        </Row>
        {presentedSample.controlSample && (
          <>
            <Row>
              <Col md={6}>
                <FormRow66
                  controlId="storageState"
                  label="Przechowywanie próbki - stan"
                  static={!editAuthorized}
                >
                  <DictionarySelector
                    dictionary="badanie próbek - przechowywanie próbki - stan"
                    value={presentedSample.storageStateId}
                    onChange={option =>
                      sampleChange({
                        ...presentedSample,
                        storageStateId: option.value,
                      })
                    }
                  />
                </FormRow66>
              </Col>
              <Col md={6}>
                <FormRow66
                  controlId="storagePlace"
                  label="Miejsce przechowywania próbki"
                  static={!editAuthorized}
                >
                  <DictionarySelector
                    dictionary="badanie próbek - miejsce przechowywania próbki"
                    value={presentedSample.storagePlaceId}
                    onChange={option =>
                      sampleChange({
                        ...presentedSample,
                        storagePlaceId: option.value,
                      })
                    }
                  />
                </FormRow66>
              </Col>
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <FormRow66
                  controlId="storagePlaceText"
                  label="Wskazane miejsce"
                  static={!editAuthorized}
                >
                  {storagePlaceId === '110955a8-97de-4b34-ab61-9e3c90a4bae4' ? (
                    <TextOrControl
                      readonly={!editAuthorized}
                      value={presentedSample.storagePlaceText}
                    >
                      <DynamicTextarea
                        value={presentedSample.storagePlaceText || ''}
                        onChange={e =>
                          setProperty({ storagePlaceText: e.target.value })
                        }
                        onBlur={presentedToSelected}
                      />
                    </TextOrControl>
                  ) : (
                    <div>{storagePlaceText}</div>
                  )}
                </FormRow66>
              </Col>
            </Row>
          </>
        )}
        <FormRow controlId="comments" label="Uwagi">
          <TextOrControl
            readonly={!editAuthorized}
            value={presentedSample.comments}
          >
            <DynamicTextarea
              value={presentedSample.comments || ''}
              onChange={e => setProperty({ comments: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
      </Form>
    </>
  );
};
