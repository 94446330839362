/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamResultHistoryDto
 */
export interface CreateUpdateExamResultHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    ts: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    examResultId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    activity: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamResultHistoryDto
     */
    date: Date;
}

export function CreateUpdateExamResultHistoryDtoFromJSON(json: any): CreateUpdateExamResultHistoryDto {
    return CreateUpdateExamResultHistoryDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamResultHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamResultHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ts': (new Date(json['ts'])),
        'examResultId': json['examResultId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'activity': json['activity'],
        'date': (new Date(json['date'])),
    };
}

export function CreateUpdateExamResultHistoryDtoToJSON(value?: CreateUpdateExamResultHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ts': (value.ts.toISOString()),
        'examResultId': value.examResultId,
        'description': value.description,
        'activity': value.activity,
        'date': (value.date.toISOString()),
    };
}


