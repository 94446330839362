import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetTechAssessmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

import { TechAssessmentsOrganizationLink } from './TechAssessmentsOrganizationLink';

export enum TechAssessmentsVariant {
  full,
  forNationalTechnicalAssessmentUnit,
  withoutOrganization,
}

interface TechAssessmentsProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  data?: GetTechAssessmentDto[];
  variant: TechAssessmentsVariant;
  onClick?: (key: string) => void;
}

export const techAssessmentForGrid = (techAssessment: GetTechAssessmentDto) => {
  return {
    key: techAssessment.id,
    values: {
      'organizationalUnit.name': (
        <OrganizationalUnitBadge
          organizationalUnit={techAssessment.organizationalUnit}
        />
      ),
      organizationalUnitName: techAssessment.organizationalUnit.name,
      'techAssessment.preparationDate': momencik(
        techAssessment.preparationDate,
      ),
      'techAssessment.number': techAssessment.number,
      'techAssessment.productName': techAssessment.productName,
      'techAssessment.expDateStart': momencik(techAssessment.expDateStart),
      'techAssessment.expDateEnd': momencik(techAssessment.expDateEnd),
      'techAssessment.repealDate': techAssessment.repealDate
        ? momencik(techAssessment.repealDate)
        : null,
      'producer.name': techAssessment.producer?.name,
      'producer.link': (
        <TechAssessmentsOrganizationLink
          producerId={techAssessment.producer.id}
        />
      ),
    },
    orderByValues: {
      'techAssessment.preparationDate': momencik(
        techAssessment.preparationDate,
        '',
      ),
    },
  };
};

export const TechAssessments: React.FC<TechAssessmentsProps> = props => {
  let columns = undefined;
  if (props.variant === TechAssessmentsVariant.full) {
    columns = [
      { header: 'Wnioskodawca', property: 'producer.name' },
      {
        header: 'Jednostka ocen technicznych',
        property: 'organizationalUnit.name',
      },
      {
        header: 'Data przygotowania oceny',
        property: 'techAssessment.preparationDate',
      },
      {
        header: 'Numer krajowej oceny technicznej',
        property: 'techAssessment.number',
      },
      {
        header: 'Nazwa wyrobu budowlanego',
        property: 'techAssessment.productName',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej od',
        property: 'techAssessment.expDateStart',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej do',
        property: 'techAssessment.expDateEnd',
      },
      {
        header: 'Data  uchylenia krajowej oceny technicznej',
        property: 'techAssessment.repealDate',
      },
      { header: '', property: 'producer.link' },
    ];
  } else if (
    props.variant === TechAssessmentsVariant.forNationalTechnicalAssessmentUnit
  ) {
    columns = [
      { header: 'Wnioskodawca', property: 'producer.name' },
      {
        header: 'Data przygotowania oceny',
        property: 'techAssessment.preparationDate',
      },
      {
        header: 'Numer krajowej oceny technicznej',
        property: 'techAssessment.number',
      },
      {
        header: 'Nazwa wyrobu budowlanego',
        property: 'techAssessment.productName',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej od',
        property: 'techAssessment.expDateStart',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej do',
        property: 'techAssessment.expDateEnd',
      },
      {
        header: 'Data  uchylenia krajowej oceny technicznej',
        property: 'techAssessment.repealDate',
      },
    ];
  } else {
    columns = [
      {
        header: 'Jednostka ocen technicznych',
        property: 'organizationalUnit.name',
      },
      {
        header: 'Data przygotowania oceny',
        property: 'techAssessment.preparationDate',
      },
      {
        header: 'Numer krajowej oceny technicznej',
        property: 'techAssessment.number',
      },
      {
        header: 'Nazwa wyrobu budowlanego',
        property: 'techAssessment.productName',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej od',
        property: 'techAssessment.expDateStart',
      },
      {
        header: 'Okres ważności Krajowej Oceny Technicznej do',
        property: 'techAssessment.expDateEnd',
      },
      {
        header: 'Data  uchylenia krajowej oceny technicznej',
        property: 'techAssessment.repealDate',
      },
    ];
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      data={props.data && props.data.map(d => techAssessmentForGrid(d))}
      columns={columns}
      pageSize={10}
      availablePageSizes={[10, 20, 50]}
      showFilter={true}
      initialOrderColumn="techAssessment.preparationDate"
      initialOrderDirection="DESC"
      onRowClick={props.onClick}
      emptyGridMessage={
        props.variant === TechAssessmentsVariant.withoutOrganization
          ? 'Brak ocen technicznych dla tego podmiotu'
          : 'Brak ocen technicznych'
      }
    />
  );
};
