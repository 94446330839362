/*eslint max-lines-per-function: ["error", 180]*/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from 'react-bootstrap';

import momencik from '../../../common/momencik';
import { ReportPublicationsType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';

type Props = {
  data: ReportPublicationsType[] | undefined;
  dataCSV: unknown[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportByPublications: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  dataCSV,
  usedFilter,
}) => {
  const hasData = (data && data.length > 0) || false;

  const headers1 = [
    {
      key: 'publicationNumber',
      label: 'Numer publikacji',
    },
    {
      key: 'examOrgUnitShortname',
      label: 'Organ zlecający',
    },
    {
      key: 'operateOrgUnitShortname',
      label: 'Organ obsługujący',
    },
    {
      key: `collectDateString`,
      label: 'Data pobrania',
    },
    {
      key: 'collectPlace',
      label: 'Miejsce pobrania',
    },
    {
      key: 'nkitw',
      label: 'NKITW/TW',
    },
    {
      key: 'description',
      label: 'Opis typu wyrobu',
    },
    {
      key: 'groupShortname',
      label: 'Grupa',
    },
    {
      key: 'subGroupShortname',
      label: 'Podgrupa',
    },
    {
      key: 'technicalSpecification',
      label: 'Specyfikacja techniczna',
    },
    {
      key: 'organization',
      label: 'Podmiot',
    },
    {
      key: 'negative',
      label: 'Wynik',
    },
    {
      key: 'controlResult',
      label: 'Próbka kontrolna',
    },
  ];

  const headers2 = [
    {
      key: 'examCostForGoverment',
      label: 'Koszt badania Skarbu Państwa',
    },
    {
      key: 'transportCostForGoverment',
      label: 'Koszt transportu Skarbu Państwa',
    },
    {
      key: 'storageCostForGoverment',
      label: 'Koszt przechowywania Skarbu Państwa',
    },
    {
      key: 'examCostForInspected',
      label: 'Koszt badania kontrolowanego',
    },
    {
      key: 'transportCostForInspected',
      label: 'Koszt transportu kontrolowanego',
    },
    {
      key: 'storageCostForInspected',
      label: 'Koszt przechowywania kontrolowanego',
    },
  ];

  const csvReport = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: dataCSV as any,
    headers: [
      ...headers1,
      ...[
        { key: 'feature', label: 'Charakterystyka' },
        { key: 'functionalProperty', label: 'Właściwość użytkowa' },
        { key: 'examMethod', label: 'Metoda' },
        { key: 'result', label: 'Wynik' },
        { key: 'expectedResultValue', label: 'Deklarowana wartość' },
        { key: 'resultValue', label: 'Wynik próbki' },
        { key: 'resultUncertainty', label: 'Niepewność wyniku badania próbki' },
      ],
      ...headers2,
    ],
    filename: 'Opublikowane_probki.csv',
  };

  return (
    <>
      <ExportToExcel
        fileName={reportName}
        csvReport={csvReport}
        filter={usedFilter}
      >
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={26}>{reportName}</th>
            </tr>
            <tr>
              {headers1.map(pub => (
                <th rowSpan={2} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
              <th colSpan={7}>Charakterystyki</th>
              {headers2.map(pub => (
                <th rowSpan={2} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
            </tr>
            <tr>
              <th>Charakterystyka</th>
              <th>Właściwość użytkowa</th>
              <th>Metoda</th>
              <th>Wynik</th>
              <th>Deklarowana wartość</th>
              <th>Wynik próbki</th>
              <th>Niepewność wyniku badania próbki</th>
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {data.map(pub => (
                <GetDataRow key={uuidv4()} pub={pub} />
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

/*eslint complexity: ["error", 12]*/
const GetDataRow: React.FC<{
  pub: ReportPublicationsType;
}> = ({ pub }) => {
  if (!pub) {
    return <React.Fragment key={uuidv4()}></React.Fragment>;
  }

  const essLength =
    pub.essentialFuture?.length && pub.essentialFuture?.length > 0
      ? pub.essentialFuture?.length
      : 1;

  return (
    <React.Fragment key={uuidv4()}>
      <tr key={uuidv4()}>
        <td rowSpan={essLength} className="no-date-parsing">
          {pub.publicationNumber}
        </td>
        <td rowSpan={essLength}>{pub.examOrgUnitShortname}</td>
        <td rowSpan={essLength}>{pub.operateOrgUnitShortname}</td>
        <td rowSpan={essLength}>{momencik(pub.collectDate)}</td>
        <td rowSpan={essLength}>{pub.collectPlace}</td>
        <td rowSpan={essLength}>{pub.nkitw}</td>
        <td rowSpan={essLength}>{pub.description}</td>
        <td rowSpan={essLength}>{pub.groupShortname}</td>
        <td rowSpan={essLength}>{pub.subGroupShortname}</td>
        <td rowSpan={essLength}>{pub.technicalSpecification}</td>
        <td rowSpan={essLength}>{pub.organization}</td>
        <td rowSpan={essLength}>{pub.negative ? 'negatywny' : 'pozytywny'}</td>
        <td rowSpan={essLength}>{pub.controlSample ? 'tak' : 'nie'}</td>
        {pub.essentialFuture && (
          <>
            <td>{pub.essentialFuture[0]?.feature}</td>
            <td>{pub.essentialFuture[0]?.functionalProperty}</td>
            <td>{pub.essentialFuture[0]?.examMethod}</td>
            <td>
              {(
                !pub.controlSample
                  ? pub.essentialFuture[0]?.result
                  : pub.essentialFuture[0]?.controlResult
              )
                ? 'zgodny'
                : (!pub.controlSample
                    ? pub.essentialFuture[0]?.result
                    : pub.essentialFuture[0]?.controlResult) === false
                ? 'niezgodny'
                : ''}
            </td>
            <td
              className="html-column"
              dangerouslySetInnerHTML={{
                __html: pub.essentialFuture[0]?.expectedResultValue,
              }}
            ></td>
            <td
              className="html-column"
              dangerouslySetInnerHTML={{
                __html: pub.essentialFuture[0]?.resultValue,
              }}
            ></td>
            <td
              className="html-column"
              dangerouslySetInnerHTML={{
                __html: pub.essentialFuture[0]?.resultUncertainty,
              }}
            ></td>
          </>
        )}
        <td rowSpan={essLength} className="costs-column">
          {pub.examCostForGoverment}
        </td>
        <td rowSpan={essLength} className="costs-column">
          {pub.transportCostForGoverment}
        </td>
        <td rowSpan={essLength} className="costs-column">
          {pub.storageCostForGoverment}
        </td>
        <td rowSpan={essLength} className="costs-column">
          {pub.examCostForInspected}
        </td>
        <td rowSpan={essLength} className="costs-column">
          {pub.transportCostForInspected}
        </td>
        <td rowSpan={essLength} className="costs-column">
          {pub.storageCostForInspected}
        </td>
      </tr>
      {pub.essentialFuture &&
        pub.essentialFuture.map((ess, index) => (
          <React.Fragment key={uuidv4()}>
            {index > 0 && (
              <tr>
                <td>{ess.feature}</td>
                <td>{ess.functionalProperty}</td>
                <td>{ess.examMethod}</td>
                <td>
                  {(!pub.controlSample ? ess.result : ess.controlResult)
                    ? 'zgodny'
                    : (!pub.controlSample ? ess.result : ess.controlResult) ===
                      false
                    ? 'niezgodny'
                    : ''}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};
