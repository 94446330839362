import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import {
  FilterExtended,
  FilterReportDataExtended,
} from '../../../components/Reports/FilterExtended';
import { ReportByNegativeTestResults } from '../../../components/ReportByPakAnalysisMoneyPenaltiesType/ReportByNegativeTestResults';
import { ReportNegativeTest } from '../../../../common/types/ReportTypes';

export const ReportsNegativeTestResultsView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<
    FilterReportDataExtended | undefined
  >({});

  const [data, setData] = useState<ReportNegativeTest[]>();

  const onSearch = (filter?: FilterReportDataExtended) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportNegativeTest(filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <FilterExtended
        onSearch={onSearch}
        reportName="Negatywne wyniki badań"
        dateFromName="Data rozpoczęcia od"
        dateToName="Data rozpoczęcia do"
        productDescription="Wyrób"
      />
      <ReportByNegativeTestResults
        data={data || []}
        dataCSV={data || []}
        reportName="Negatywne wyniki badań"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
