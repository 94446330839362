import { GetExamResultHistoryDto } from '../autogenerated/snrwbApiClient/models/GetExamResultHistoryDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateExamResultHistoryDto,
  CreateUpdateExamResultHistoryDtoFromJSON,
  GetLastApprovalDateDto,
} from '../autogenerated/snrwbApiClient/models';

export interface ExamResultHistoryContextInterface {
  create: (
    dto: CreateUpdateExamResultHistoryDto,
  ) => Promise<GetExamResultHistoryDto>;
  getByExamResult: (examResultId: string) => Promise<GetExamResultHistoryDto[]>;
  getLastApprovalDate: (
    examResultId: string,
  ) => Promise<GetLastApprovalDateDto>;
}

export const ExamResultHistoryContext = (api: DefaultApi) => {
  return {
    create: (dto: CreateUpdateExamResultHistoryDto) =>
      api.examResultHistoryControllerCreate(dto),
    getByExamResult: (examResultId: string) =>
      api.examResultHistoryControllerGetByExamResult(examResultId),
    getLastApprovalDate: (examResultId: string) =>
      api.examResultHistoryControllerGetLastApprovalDate(examResultId),
  };
};

export const toCuDto = (getDto: GetExamResultHistoryDto) => {
  const dto = CreateUpdateExamResultHistoryDtoFromJSON(getDto);
  return dto;
};
