import momencik from '../../momencik';
import { NotificationsProviderType } from '../../notifications';
import {
  GetPakDto,
  CreateUpdatePakDto,
  ValidationStatus,
  CreateUpdatePakDtoFromJSON,
  GetDictionaryDto,
  CreateUpdateJudgmentDto,
  CreateUpdateProductTypeDto,
  CreateUpdateOrganizationDto,
  CreateUpdatePartyToProceedingDto,
  GetPakExtendedDto,
  GetSampleDto,
  GetInspectedProductDto,
  GetOrganizationDto,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { SnrwbCoreContextType } from '../snrwbCore';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { pakForGrid } from '../../../snrwb/components/Paks/PakSearch';
import nkitwDesc from '../../nkitwDesc';
import { GridFilters } from '../../components/Grid/GridFilters';

import { ObjectType } from './AttachmentContext';

export interface PakContextInterface {
  getById: (id: string) => Promise<GetPakDto>;
  getPendingForUser: () => Promise<GetPakExtendedDto[]>;
  getFinishedForUser: () => Promise<GetPakExtendedDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    additionalId?: string,
    additionalFilters?: GridFilters,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getByProceeding: (proceedingId: string) => Promise<GetPakExtendedDto[]>;
  getBySample: (sampleId: string) => Promise<GetPakDto[]>;
  getBySamples: (samples: GetSampleDto[]) => Promise<GetPakDto[]>;
  getByInspectedProduct: (inspectedProductId: string) => Promise<GetPakDto[]>;
  getByInspection: (products: GetInspectedProductDto[]) => Promise<GetPakDto[]>;
  createBasedOnProceeding: (
    proceedingId: string,
    organizationId: string,
  ) => Promise<GetPakDto>;
  createBasedOnSample: (sampleId: string) => Promise<GetPakDto>;
  createBasedOnInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetPakDto>;
  update: (id: string, dto: CreateUpdatePakDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
  mayBeApproved: (id: string) => Promise<ValidationStatus>;
}

export const PakContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.pakControllerGet(id),
    getPendingForUser: () => api.pakControllerGetPendingForUser(),
    getFinishedForUser: () => api.pakControllerGetFinishedForUser(),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      additionalId?: string,
      additionalFilters?: GridFilters,
    ) => {
      const data = await api.pakControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        additionalFilters?.organizationalUnit || '',
        additionalFilters &&
          additionalFilters.organization &&
          (additionalFilters.organization as GetOrganizationDto).id
          ? (additionalFilters.organization as GetOrganizationDto).id
          : '',
        additionalFilters?.productType || '',
        additionalFilters && additionalFilters.startDateFrom
          ? additionalFilters.startDateFrom.toISOString().substring(0, 10)
          : '',
        additionalFilters && additionalFilters.startDateTo
          ? additionalFilters.startDateTo.toISOString().substring(0, 10)
          : '',
        additionalFilters && additionalFilters.endDateFrom
          ? additionalFilters.endDateFrom.toISOString().substring(0, 10)
          : '',
        additionalFilters && additionalFilters.endDateTo
          ? additionalFilters.endDateTo.toISOString().substring(0, 10)
          : '',
        additionalFilters?.fileNumber || '',
      );
      return data.map(pakForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.pakControllerGetAllCount(filterText || ''),
    getByProceeding: (proceedingId: string) =>
      api.pakControllerGetByProceeding(proceedingId),
    getBySample: (sampleId: string) => api.pakControllerGetBySample(sampleId),
    getBySamples: async (samples: GetSampleDto[]) => {
      const result = [];
      for (const sample of samples) {
        result.push(...(await api.pakControllerGetBySample(sample.id)));
      }
      return result;
    },
    getByInspectedProduct: (inspectedProductId: string) =>
      api.pakControllerGetByInspectedProduct(inspectedProductId),
    getByInspection: async (products: GetInspectedProductDto[]) => {
      const result = [];
      for (const product of products) {
        result.push(
          ...(await api.pakControllerGetByInspectedProduct(product.id)),
        );
      }
      return result;
    },
    createBasedOnProceeding: (proceedingId: string, organizationId: string) =>
      api.pakControllerCreateBasedOnProceeding({
        proceedingId,
        organizationId,
      }),
    createBasedOnSample: (sampleId: string) =>
      api.pakControllerCreateBasedOnSample({ id: sampleId }),
    createBasedOnInspectedProduct: (inspectedProductId: string) =>
      api.pakControllerCreateBasedOnInspectedProduct({
        id: inspectedProductId,
      }),
    update: (id: string, dto: CreateUpdatePakDto) =>
      api.pakControllerUpdate(id, dto),
    delete: (id: string) => api.pakControllerDelete(id),
    approve: (id: string) => api.pakControllerApprove(id),
    revertApprove: (id: string) => api.pakControllerRevertApprove(id),
    mayBeApproved: (id: string) => api.pakControllerMayBeApproved(id),
  };
};

export const toCuDto = (getDto: GetPakDto) => {
  const dto = CreateUpdatePakDtoFromJSON(getDto);
  if (getDto.organizationalUnit) {
    dto.organizationalUnitId = getDto.organizationalUnit.id;
  }
  if (getDto.sample) {
    dto.sampleId = getDto.sample.id;
  }
  if (getDto.productType) {
    dto.productTypeId = getDto.productType.id;
  }

  return dto;
};

export const detailsPresent = (pak: GetPakDto, showPaidAmount: boolean) => {
  const form: { name: string; value: string }[] = [];

  if (pak.productType) {
    form.push({
      name: 'Typ wyrobu',
      value: nkitwDesc(pak.productType),
    });
  }
  if (pak.startDate) {
    form.push({
      name: 'Data wszczęcia',
      value: momencik(pak.startDate),
    });
  }
  if (pak.endDate) {
    form.push({
      name: 'Data zakończenia',
      value: momencik(pak.endDate),
    });
  }
  if (pak.quantity) {
    form.push({
      name: 'Ilość wyrobu',
      value: pak.quantity,
    });
  }
  if (pak.penaltyAmount) {
    form.push({
      name: 'Wysokość kary',
      value: pak.penaltyAmount,
    });
  }
  if (showPaidAmount && pak.paidAmount) {
    form.push({
      name: 'Zapłacona należność',
      value: pak.paidAmount,
    });
  }

  return form;
};

export const forPakView = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const pak = await snrwb.paks.getById(id);
  const parties = await snrwb.partiesToProceedings.getByPak(id);
  const proceeding = pak.proceedingId
    ? await snrwb.proceedings.getById(pak.proceedingId)
    : undefined;
  const attachments = await snrwb.attachments.getAllForObject(
    ObjectType.Pak,
    id,
  );
  const judgments = await snrwb.judgments.getByPak(id);

  const judgmentTypes = await snrwb.dictionaries.getByDictionaryType(
    'orzeczenie - typ',
  );
  const tPenalty = judgmentTypes.filter(jt => jt.shortname === 'kara')[0];
  const tCodex = judgmentTypes.filter(jt => jt.shortname === 'kodeks')[0];

  const isChild = (child: GetDictionaryDto, parent: GetDictionaryDto) => {
    let node = child;
    while (node.parentId) {
      node = judgmentTypes.filter(ju => ju.id === node.parentId)[0];
    }
    return node.id === parent.id;
  };

  const penalties = judgments.filter(ju => isChild(ju.kind, tPenalty));
  const codex = judgments.filter(ju => isChild(ju.kind, tCodex));

  const ac = AttachmentContext.forAssociatedDocuments;
  const ju = JudgmentContext.forAssociatedDocuments;

  const assocAttachments = ac(snrwb, notifications, attachments, action);
  const assocPenalties = ju(snrwb, notifications, penalties, action);
  const assocCodex = ju(snrwb, notifications, codex, action);

  assocAttachments.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.Pak;
    attachment.objectId = id;
    return attachment;
  };

  const juAdd = assocPenalties.add;
  const juAddWrapped = (dto: CreateUpdateJudgmentDto) => {
    dto.pakId = id;
    juAdd(dto);
  };
  assocPenalties.add = juAddWrapped;
  assocCodex.add = juAddWrapped;

  return {
    pak,
    parties,
    proceeding,
    penalties: assocPenalties,
    codex: assocCodex,
    attachments: assocAttachments,
  };
};

export interface PakViewApi {
  changePak: (dto: CreateUpdatePakDto) => void;
  approvePak: () => void;
  revertApprovePak: () => void;
  mayPakBeApproved: () => Promise<ValidationStatus>;
  deletePak: () => void;
  changeProductType: (productType: CreateUpdateProductTypeDto) => void;
  approveProductType: (status: ValidationStatus) => void;
  revertApproveProductType: () => void;
  mayProductTypeBeApproved: (id: string) => Promise<ValidationStatus>;
  addParty: (dto: CreateUpdatePartyToProceedingDto) => void;
  addPartyWithOrganization: (
    orgDto: CreateUpdateOrganizationDto,
    dto: CreateUpdatePartyToProceedingDto,
  ) => void;
  validateNewParty: (
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<ValidationStatus>;
  deleteParty: (id: string) => void;
  refresh: () => void;
}

export const handlersForPakView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  pak: GetPakDto,
  refreshAction: () => void,
  deleteAction: () => void,
) => {
  return {
    changePak: (dto: CreateUpdatePakDto) =>
      notifications.onPromise(snrwb.paks.update(pak.id, dto), refreshAction),

    approvePak: () =>
      notifications.onPromise(snrwb.paks.approve(pak.id), refreshAction),

    revertApprovePak: () =>
      notifications.onPromise(snrwb.paks.revertApprove(pak.id), refreshAction),

    mayPakBeApproved: () => snrwb.paks.mayBeApproved(pak.id),

    deletePak: () =>
      notifications.onPromise(
        snrwb.paks.delete(pak.id),
        deleteAction,
        refreshAction,
      ),

    changeProductType: (dto: CreateUpdateProductTypeDto) =>
      notifications.onPromise(
        snrwb.productTypes.update(pak.productType.id, dto),
        refreshAction,
      ),

    approveProductType: (status: ValidationStatus) =>
      notifications.onPromise(
        snrwb.productTypes.approve(pak.productType.id, status),
        refreshAction,
      ),

    revertApproveProductType: () => {
      notifications.onPromise(
        snrwb.productTypes.revertApprove(pak.productType.id),
        refreshAction,
      );
    },

    mayProductTypeBeApproved: (id: string) =>
      snrwb.productTypes.mayBeApproved(id),

    addParty: (dto: CreateUpdatePartyToProceedingDto) =>
      notifications.onPromise(
        (async () => {
          await snrwb.partiesToProceedings.create(dto);
        })(),
        refreshAction,
      ),

    addPartyWithOrganization: (
      orgDto: CreateUpdateOrganizationDto,
      dto: CreateUpdatePartyToProceedingDto,
    ) =>
      notifications.onPromise(
        (async () => {
          await snrwb.partiesToProceedings.createWithOrganization(orgDto, dto);
        })(),
        refreshAction,
      ),

    validateNewParty: (dto: CreateUpdatePartyToProceedingDto) =>
      snrwb.partiesToProceedings.validateNew(dto),

    deleteParty: (id: string) =>
      notifications.onPromise(
        snrwb.partiesToProceedings.delete(id),
        refreshAction,
      ),

    refresh: refreshAction,
  };
};
