/*eslint max-lines-per-function: ["error", 180]*/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from 'react-bootstrap';

import momencik from '../../../common/momencik';
import { ReportControlSampleAnalysisType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';

type Props = {
  data: ReportControlSampleAnalysisType[] | undefined;
  dataCSV: unknown[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportByControlSampleAnalysisType: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = (data && data.length > 0) || false;

  const headers1 = [
    {
      key: 'entityName',
      label: 'Strona (podmiot z pobrania)',
    },
    {
      key: 'productDescription',
      label: 'Wyrób',
    },
  ];
  const headers2 = [
    {
      key: 'activityDate',
      label: 'Data rozpoczęcia',
    },
    {
      key: 'protocolSigningDate',
      label: 'Data podpisania protokołu z pobrania',
    },
  ];
  const headers3 = [
    {
      key: 'unitSymbol',
      label: 'Organ', // 5
    },
    {
      key: 'recordNumber',
      label: 'Nr akt', // 6
    },
    {
      key: 'storageCondition',
      label: 'Stan próbki kontrolnej (zniszczenie)', // 7
    },
  ];

  const headers4 = [
    {
      key: 'type',
      label: 'Podstawa',
    },
    {
      key: 'organizationalUnit',
      label: 'Organ',
    },
    {
      key: 'issueDate',
      label: 'Data wydania',
    },
    {
      key: 'sign',
      label: 'Znak',
    },
    {
      key: 'appeal',
      label: 'Odwołanie',
    },
    {
      key: 'appealResult',
      label: 'Wynik odwołania',
    },
  ];

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              {headers1.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
              <th colSpan={2}>Data pobrania</th>
              {headers3.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
              <th colSpan={6}>Decyzja</th>
            </tr>
            <tr>
              {headers2.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
              {headers4.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {data.map(pub => (
                <GetDataRow key={uuidv4()} pub={pub} />
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const GetDataRow: React.FC<{
  pub: ReportControlSampleAnalysisType;
}> = ({ pub }) => {
  if (!pub) {
    return <React.Fragment key={uuidv4()}></React.Fragment>;
  }

  const judLength =
    pub.judgments?.length && pub.judgments?.length > 0
      ? pub.judgments?.length
      : 1;
  return (
    <React.Fragment key={uuidv4()}>
      <tr>
        <td rowSpan={judLength}>{pub.entityName}</td>
        <td rowSpan={judLength}>{pub.productDescription}</td>
        <td rowSpan={judLength}>{momencik(pub.activityDate)}</td>
        <td rowSpan={judLength}>{momencik(pub.protocolSigningDate)}</td>
        <td rowSpan={judLength}>{pub.unitSymbol}</td>
        {/* <td rowSpan={judLength}>{pub.recordNumber}</td> */}
        <td rowSpan={judLength}>
          {pub.recordNumber && (
            <a href={'/badania/pobranie/' + pub.sampleCollectId}>
              {pub.recordNumber}
            </a>
          )}
        </td>
        <td rowSpan={judLength}>{pub.storageCondition}</td>
        {pub.judgments && (
          <>
            <td>{pub.judgments[0]?.type}</td>
            <td>{pub.judgments[0]?.organizationalUnit}</td>
            <td>
              {pub.judgments[0]?.issueDate?.toString() !== 'Invalid Date'
                ? momencik(pub.judgments[0]?.issueDate)
                : ''}
            </td>
            <td>{pub.judgments[0]?.sign}</td>
            <td>
              {pub.judgments[0]?.appeal === true
                ? 'Tak'
                : pub.judgments[0]?.appeal === false
                ? 'Nie'
                : ''}
            </td>
            <td>{pub.judgments[0]?.appealResult}</td>
          </>
        )}
      </tr>
      {pub.judgments &&
        pub.judgments.map((pub, index) => (
          <React.Fragment key={uuidv4()}>
            {index > 0 && (
              <tr>
                <td>{pub.type}</td>
                <td>{pub.organizationalUnit}</td>
                <td>{momencik(pub.issueDate)}</td>
                <td>{pub.sign}</td>
                <td>
                  {pub.appeal === true
                    ? 'Tak'
                    : pub.appeal === false
                    ? 'Nie'
                    : ''}
                </td>
                <td>{pub.appealResult}</td>
              </tr>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};
