import React from 'react';

import { GetProceedingWithPartiesDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import nkitwDesc from '../../../common/nkitwDesc';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';

import { ProceedingLink } from './ProceedingLink';
import { ProceedingType } from './ProceedingType';

export const proceedingForGrid = (proceeding: GetProceedingWithPartiesDto) => {
  return {
    key: proceeding.id,
    values: {
      type: (
        <ProceedingType
          proceeding={proceeding}
          productType={proceeding.inspectedProduct.productType}
        />
      ),
      'organizationalUnit.shortname': (
        <OrganizationalUnitBadge
          organizationalUnit={proceeding.organizationalUnit}
        />
      ),
      startDate: momencik(proceeding.startDate),
      endDate: momencik(proceeding.endDate),
      'productType.nkitw': nkitwDesc(proceeding.inspectedProduct.productType),
      parties: proceeding.parties,
      link: <ProceedingLink proceedingId={proceeding.id} />,
      actNumber: proceeding.actNumber,
    },
    orderByValues: {
      startDate: momencik(proceeding.startDate, ''),
    },
  };
};

export const ProceedingsSearch: React.FC<{
  getData: GridGetterFunction;
  getCount: GridCountFunction;
}> = props => {
  const nav = useNavigation();
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Organ', property: 'organizationalUnit.shortname' },
    { header: 'Data wszczęcia', property: 'startDate' },
    { header: 'Data zakończenia', property: 'endDate' },
    { header: 'Typ wyrobu', property: 'productType.nkitw' },
    { header: 'Strony', property: 'parties' },
    { header: 'Numer akt', property: 'actNumber' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      initialOrderColumn="startDate"
      initialOrderDirection="DESC"
      columns={columns}
      showFilter
      onRowClick={nav.proceeding}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak pasujących postępowań"
      defaultAdditionalFilters={{
        organizationalUnit: '',
        organization: OrganizationContext.newOrganization(),
        productType: '',
        startDateFrom: null,
        startDateTo: null,
        endDateFrom: null,
        endDateTo: null,
        fileNumber: '',
      }}
    />
  );
};
