import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { GetOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';

export default (props: { organizationalUnit: GetOrganizationalUnitDto }) => {
  return (
    <>
      {props.organizationalUnit?.shortname && (
        <Badge bg="warning" text="dark">
          {props.organizationalUnit.shortname}
        </Badge>
      )}
      {props.organizationalUnit?.notified === true && (
        <span className="ms-1">{props.organizationalUnit.name}</span>
      )}
    </>
  );
};
