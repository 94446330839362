/*eslint max-lines-per-function: ["error", 300]*/
import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import _ from 'lodash';

import FormRow, {
  FormRow48,
  FormRow64,
  FormRow57R,
  FormRow84R,
} from '../../../app/components/FormRow';
import {
  GetInspectedProductDto,
  GetMisstatementDto,
  CreateUpdateInspectedProductDto,
  CreateUpdateMisstatementDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import * as InspectedProductContext from '../../../common/snrwbCore/contexts/InspectedProductContext';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import TextOrControl from '../../../app/components/TextOrControl';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';
import MultiColumnValue, {
  Column,
} from '../../../app/components/MultiColumnValue';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';

import InspectionScope from './InspectionScope';

export const InspectedProductDetails: React.FC<{
  readonly: boolean;
  checkingInspection: boolean;
  onChange: (product: CreateUpdateInspectedProductDto) => void;
  product: GetInspectedProductDto;
  misstatements: AssociateDocuments<
    GetMisstatementDto,
    CreateUpdateMisstatementDto
  >;
}> = props => {
  const [product, setProduct] = useState(
    InspectedProductContext.convert(props.product),
  );
  const [presentedProduct, setPresentedProduct] = useState(product);

  const saveIfChanged = (obj: CreateUpdateInspectedProductDto) => {
    if (_.isEqual(product, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const propertyChange = (obj: Partial<CreateUpdateInspectedProductDto>) => {
    setPresentedProduct({ ...presentedProduct, ...obj });
  };
  const productChange = (obj: Partial<CreateUpdateInspectedProductDto>) => {
    const dto = { ...product, ...obj };
    setProduct(dto);
    saveIfChanged(dto);
  };

  const presentedToSelected = () => {
    setProduct(presentedProduct);
    saveIfChanged(presentedProduct);
  };

  const changeAndBlur = (obj: Partial<CreateUpdateInspectedProductDto>) => {
    setPresentedProduct({ ...presentedProduct, ...obj });
    setProduct({ ...presentedProduct, ...obj });
    saveIfChanged({ ...presentedProduct, ...obj });
  };

  useEffect(() => {
    const product = { ...props.product };
    if (props.misstatements.documents.length) {
      product.misstatementFound = true;
    } else {
      product.misstatementFound = false;
    }
    setProduct(InspectedProductContext.convert(product));
  }, [props.product, props.misstatements]);

  useEffect(() => {
    setPresentedProduct(product);
  }, [product]);

  const bool = (value: boolean) => (value ? 'Tak' : 'Nie');

  const columnsForYear: (
    inspectedProduct: CreateUpdateInspectedProductDto,
  ) => Column[] = (inspectedProduct: CreateUpdateInspectedProductDto) => [
    {
      label: 'Rok',
      type: 'number',
      key: 'yearOfProduction',
      value: inspectedProduct.yearOfProduction,
      onClickNotNA: () => propertyChange({ yearOfProductionNA: false }),
    },
    {
      label: 'Brak',
      type: 'n/a',
      key: 'yearOfProductionNA',
      value: inspectedProduct.yearOfProductionNA,
    },
  ];
  const columnsForPlace: (
    inspectedProduct: CreateUpdateInspectedProductDto,
  ) => Column[] = (inspectedProduct: CreateUpdateInspectedProductDto) => [
    {
      label: 'Miejsce',
      type: 'dynamic',
      key: 'placeOfProduction',
      value: inspectedProduct.placeOfProduction,
      onClickNotNA: () => propertyChange({ placeOfProductionNA: false }),
    },
    {
      label: 'Brak',
      type: 'n/a',
      key: 'placeOfProductionNA',
      value: inspectedProduct.placeOfProductionNA,
    },
  ];

  return (
    <>
      <InspectionScope
        readonly={props.readonly}
        checkingInspection={props.checkingInspection}
        product={props.product}
        presentedProduct={presentedProduct}
        productChange={productChange}
      />
      <Form className="d-grid gap-3">
        <FormRow controlId="subtype" label="Podtyp" boldLabel>
          <TextOrControl
            readonly={props.readonly}
            value={presentedProduct.subtype}
          >
            <Form.Control
              type="text"
              value={presentedProduct.subtype || ''}
              onChange={e => propertyChange({ subtype: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="purpose" label="Zamierzone zastosowanie" boldLabel>
          <TextOrControl
            readonly={props.readonly}
            value={presentedProduct.purpose}
          >
            <Form.Control
              type="text"
              value={presentedProduct.purpose || ''}
              onChange={e => propertyChange({ purpose: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="quantity" label="Ilość" boldLabel>
          <TextOrControl
            readonly={props.readonly}
            value={presentedProduct.quantity}
          >
            <RichTextSingleLine
              value={presentedProduct.quantity || ''}
              onChange={v => changeAndBlur({ quantity: v })}
              onlyIndexes={true}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="description" label="Opis" boldLabel>
          <TextOrControl
            readonly={props.readonly}
            value={presentedProduct.description}
          >
            <DynamicTextarea
              value={presentedProduct.description || ''}
              onChange={e => propertyChange({ description: e.target.value })}
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="technicalSpecification" label="Specyfikacja">
          <TextOrControl
            readonly={props.readonly}
            value={presentedProduct.technicalSpecification}
          >
            <DynamicTextarea
              value={presentedProduct.technicalSpecification || ''}
              onChange={e =>
                propertyChange({ technicalSpecification: e.target.value })
              }
              onBlur={presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <Row>
          <Col md={9}>
            <FormRow48
              controlId="placeOfProduction"
              label="Miejsce produkcji"
              boldLabel
            >
              <TextOrControl
                readonly={props.readonly}
                value={
                  presentedProduct.placeOfProductionNA
                    ? 'Brak'
                    : presentedProduct.placeOfProduction || ''
                }
              >
                <MultiColumnValue
                  variant="outline-secondary"
                  onChange={propertyChange}
                  onBlur={presentedToSelected}
                  columns={columnsForPlace(presentedProduct)}
                />
              </TextOrControl>
            </FormRow48>
          </Col>
          <Col md={3}>
            <FormRow57R
              controlId="yearOfProduction"
              label="Rok produkcji"
              boldLabel
            >
              <TextOrControl
                readonly={props.readonly}
                value={
                  presentedProduct.yearOfProductionNA
                    ? 'Brak'
                    : presentedProduct.yearOfProduction
                    ? presentedProduct.yearOfProduction.toString()
                    : ''
                }
              >
                <MultiColumnValue
                  variant="outline-secondary"
                  onChange={propertyChange}
                  onBlur={presentedToSelected}
                  columns={columnsForYear(presentedProduct)}
                />
              </TextOrControl>
            </FormRow57R>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormRow64
              controlId="misstatementFound"
              label="Stwierdzono nieprawidłowości"
            >
              <TextOrControl
                readonly={true}
                value={bool(presentedProduct.misstatementFound)}
              >
                <BooleanDropdown
                  value={presentedProduct.misstatementFound}
                  onChange={value =>
                    productChange({ misstatementFound: value })
                  }
                />
              </TextOrControl>
            </FormRow64>
          </Col>
          <Col md={6}>
            <FormRow84R
              controlId="analyzisInProgress"
              label="Badanie w toku kontroli"
            >
              <TextOrControl
                readonly={props.readonly}
                value={bool(presentedProduct.analyzisInProgress)}
              >
                <BooleanDropdown
                  value={presentedProduct.analyzisInProgress}
                  onChange={value =>
                    productChange({ analyzisInProgress: value })
                  }
                />
              </TextOrControl>
            </FormRow84R>
          </Col>
        </Row>
      </Form>
    </>
  );
};
