import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {
  GetPakExtendedDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';

export const ProceedingPaks = (props: {
  value: GetProceedingDto;
  paks: GetPakExtendedDto[];
}) => {
  const nav = useNavigation();

  const heading =
    props.paks.length > 0
      ? `W wyniku postępowania utworzono PAK`
      : 'Nie ma PAK dla tego postępowania';
  if (props.paks.length === 0) {
    return null;
  }
  return (
    <Alert variant="warning">
      <Alert.Heading>{heading}</Alert.Heading>
      {props.paks.length > 0 && (
        <>
          <hr />
          {props.paks.map(pe => (
            <div
              className="d-flex justify-content-between mt-1"
              key={pe.pak.id}
            >
              <div className="align-self-center">
                {pe.parties.map(p => p.name).join(', ')}
              </div>
              <div>
                <Button
                  variant="outline-primary"
                  className="mx-1"
                  onClick={() => nav.pak(pe.pak.id)}
                >
                  Pokaż PAK{' '}
                  {pe.pak.startDate ? 'z ' + momencik(pe.pak.startDate) : ''}
                </Button>
              </div>
            </div>
          ))}
        </>
      )}
    </Alert>
  );
};
