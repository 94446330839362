import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { TabPane } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import {
  GetInspectedProductDto,
  GetPartyToProceedingDto,
  GetProceedingDto,
  CreateUpdateAttachmentDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  GetAttachmentDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetPakExtendedDto,
  GetSampleExtendedDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { ProceedingViewApi } from '../../../common/snrwbCore/contexts/ProceedingContext';
import { ProductTypeEdit } from '../ProductTypes/ProductTypeEdit';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { PartiesToProceeding } from '../PartyToProceedings/PartiesToProceeding';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import oneliner from '../../../common/oneliner';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import nkitwDesc from '../../../common/nkitwDesc';
import { useNavigation } from '../../../common/navigation';

import { ProceedingApprovement } from './ProceedingApprovement';
import { ProceedingDetails } from './ProceedingDetails';
import { ProceedingAssociates } from './ProceedingAssociates';
import { ProceedingInspections } from './ProceedingInspections';
import { ProceedingSampleExams } from './ProceedingSampleExams';
import { ProceedingPaks } from './ProceedingPaks';
import { ProceedingKwz } from './ProceedingKwz';

export const Proceeding = (props: {
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  value: GetProceedingDto;
  bannerId?: string;
  inspectedProduct: GetInspectedProductDto;
  checkingProduct?: GetInspectedProductDto;
  paks: GetPakExtendedDto[];
  parties: GetPartyToProceedingDto[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  decisions: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  repairs: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  codex: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  api: ProceedingViewApi;
  samples: GetSampleExtendedDto[];
}) => {
  const nav = useNavigation();
  const [bannerId, setBannerId] = useState(props.bannerId);
  const [key, setKey] = useState({
    scope: DataChangeScope.proceeding,
    id: props.value.id,
  });
  const [description, setDescription] = useState(
    'PA w sprawie ' + nkitwDesc(props.inspectedProduct.productType),
  );

  const name = oneliner(
    `Postępowanie administracyjne w sprawie ${nkitwDesc(
      props.inspectedProduct.productType,
    )}`,
    100,
  );

  useEffect(() => {
    setBannerId(props.bannerId);
  }, [props.bannerId]);

  useEffect(() => {
    setKey({ scope: DataChangeScope.proceeding, id: props.value.id });
    setDescription(
      'PA w sprawie ' + nkitwDesc(props.inspectedProduct.productType),
    );
  }, [props.value.id, props.inspectedProduct.productType]);

  return (
    <Card className="mb-3">
      <Tabs
        defaultActiveKey={bannerId ? 'docs' : 'details'}
        id="proceeding"
        className="mb-3"
      >
        <Tab eventKey="details" title={name} className="m-3">
          <ProceedingApprovement
            editAuthorized={props.editAuthorized}
            validOrganizationalUnit={props.validOrganizationalUnit}
            value={props.value}
            decisions={props.decisions.documents}
            onApprove={props.api.approveProceeding}
            onRevertApprove={props.api.revertApproveProceeding}
            mayBeApproved={props.api.mayProceedingBeApproved}
          />
          <PartiesToProceeding
            readonly={props.value.approved || !props.editAuthorized}
            proceeding={props.value}
            parties={props.parties}
            addParty={props.api.addParty}
            addPartyWithOrganization={props.api.addPartyWithOrganization}
            validateNewParty={props.api.validateNewParty}
            deleteParty={props.api.deleteParty}
          />
          <ProceedingDetails
            value={props.value}
            editAuthorized={props.editAuthorized}
            onChange={props.api.changeProceeding}
          />
          {props.editAuthorized && !props.value.approved && (
            <div className="d-flex justify-content-end">
              <ConfirmationButton
                variant="outline-danger"
                roleAnyOf={[Role.ProceedingsRevoke]}
                confirmation="Czy na pewno usunąć postępowanie?"
                onOK={props.api.deleteProceeding}
                className="mx-1"
              >
                Usuń postępowanie
              </ConfirmationButton>
            </div>
          )}
          <div>
            <Button
              variant="outline-primary"
              onClick={() =>
                nav.inspection(props.value.inspectedProduct.inspection.id)
              }
            >
              Przejdź do kontroli
            </Button>
          </div>
        </Tab>
        <TabPane
          eventKey="product"
          title="Typ wyrobu"
          className="m-3"
          mountOnEnter={false}
          unmountOnExit={false}
        >
          <ProductTypeEdit
            readonly={props.value.approved || !props.editAuthorized}
            productType={props.inspectedProduct.productType}
            onChange={props.api.changeProductType}
            onApprove={props.api.approveProductType}
            onRevertApprove={props.api.revertApproveProductType}
            mayBeApproved={() =>
              props.api.mayProductTypeBeApproved(
                props.inspectedProduct.productType.id,
              )
            }
            refresh={props.api.refresh}
          />
        </TabPane>
        <Tab eventKey="docs" title="Dokumenty" className="mx-3">
          <ProceedingInspections {...props} />
          <ProceedingSampleExams {...props} />
          <ProceedingPaks {...props} />
          <ProceedingKwz {...props} />
          <ProceedingAssociates
            {...props}
            bannerId={bannerId}
            onBannerClosed={() => setBannerId(undefined)}
          />
        </Tab>
      </Tabs>
      <ConcurrencySignaler
        dataKey={key}
        objectDescription={description}
        editAllowed={props.editAuthorized}
        refreshAction={props.api.refresh}
      />
    </Card>
  );
};
