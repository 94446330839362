import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  GetPakDto,
  GetPartyToProceedingDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Pak } from '../../components/Paks/Pak';
import * as PakContext from '../../../common/snrwbCore/contexts/PakContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { useNavigation } from '../../../common/navigation';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useCookies } from '../../../common/hooks/useCookies';

interface ParamTypes {
  id: string;
  bannerId: string;
}

export const PakView: React.FC = () => {
  const { id, bannerId } = useParams<ParamTypes>();
  const snrwb = useSnrwbCore();
  const auth = useAuth();
  const notifications = useNotifications();
  const nav = useNavigation();
  const cookies = useCookies();
  const [pak, setPak] = useState<GetPakDto>();
  const [parties, setParties] = useState<GetPartyToProceedingDto[]>([]);
  const [proceeding, setProceeding] = useState<GetProceedingDto>();
  const [attachments, setAttachments] = useState(
    AttachmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [penalties, setPenalties] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [codex, setCodex] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );

  const mountedRef = useRef(false);

  const refresh = useCallback(() => {
    PakContext.forPakView(snrwb, notifications, id, refresh)
      .then(model => {
        if (mountedRef.current) {
          setPak(model.pak);
          setParties(model.parties);
          setProceeding(model.proceeding);
          setPenalties(model.penalties);
          setCodex(model.codex);
          setAttachments(model.attachments);
        }
      })
      .catch(() =>
        notifications.badLink(
          'Brak PAK w bazie danych. Mogło zostać przed chwilą usunięte.',
          '/postepowania/pak',
        ),
      );
  }, [id, notifications, snrwb]);

  useEffect(() => {
    mountedRef.current = true;

    refresh();

    return () => {
      mountedRef.current = false;
    };
  }, [refresh, id]);

  const unitsMatch =
    pak?.organizationalUnit?.id === cookies.organizationalUnit?.id;

  return pak ? (
    <Pak
      editAuthorized={auth.check(Role.PakEdit) && unitsMatch}
      validOrganizationalUnit={unitsMatch}
      value={pak}
      bannerId={bannerId}
      parties={parties}
      proceeding={proceeding}
      penalties={penalties}
      codex={codex}
      attachments={attachments}
      api={PakContext.handlersForPakView(
        snrwb,
        notifications,
        pak,
        refresh,
        nav.myPaks,
      )}
    />
  ) : (
    <FormSkeleton />
  );
};
