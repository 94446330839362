import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  TimelineProcessType,
  GetSampleExamDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Timeline } from '../../components/ProductTypes/Timeline';

interface ParamTypes {
  id: string;
}

export const SampleExamResultTimelineView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const { id } = useParams<ParamTypes>();

  const mountedRef = useRef(false);
  const [sampleExam, setSampleExam] = useState(GetSampleExamDtoFromJSON({}));
  const [processes, setProcesses] = useState<TimelineProcessType[]>([]);

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    snrwb.examResults
      .getById(id)
      .then(result => {
        safe(() => setSampleExam(result.sample.sampleExam));
      })
      .catch(() => notifications.badLink());

    snrwb.reports
      .getExamResultHistoryTimeline(id)
      .then(events => {
        safe(() => setProcesses(events));
      })
      .catch(() => notifications.badLink());
  }, [snrwb, notifications, id]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.inspections, refresh]);

  const [header, setHeader] = useState('Oś czasu');
  useEffect(() => {
    setHeader(
      `Oś czasu dla publikacji badań o numerze akt ${sampleExam.fileNumber} nr ${sampleExam.sysNumber}`,
    );
  }, [sampleExam]);

  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Card>
          <Timeline processes={processes} hideEvents />
        </Card>
      </Card.Body>
    </Card>
  );
};
