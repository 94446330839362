import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Skeleton from 'react-loading-skeleton';
import * as Icon from 'react-bootstrap-icons';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateSampleCollectDto,
  GetSampleCollectExtendedDto,
  GetSampleExamExtendedDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { SampleCollectsList } from '../../components/SampleCollects/SampleCollectsList';
import { SampleExamsList } from '../../components/SampleExams/SampleExamsList';
import { SampleExamSearch } from '../../components/SampleExams/SampleExamSearch';
import { useNavigation } from '../../../common/navigation';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { AddSampleCollect } from '../../components/SampleCollects/AddSampleCollect';
import { TabsHistory } from '../../../app/components/TabsHistory';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';

export const SampleExamsView: React.FC = () => {
  const nav = useNavigation();
  const snrwb = useSnrwbCore();
  const [mySampleCollects, setMySampleCollects] =
    useState<GetSampleCollectExtendedDto[]>();
  const [examsWithoutResults, setExamsWithoutResults] = useState<
    GetSampleExamExtendedDto[]
  >([]);
  const [redirectId, setRedirectId] = useState<string>();

  const [duringInspectionBasisId, setDuringInspectionBasisId] =
    useState<string>();

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      setDuringInspectionBasisId(
        SampleCollectContext.duringInspectionBasisId(),
      );
    });
  }, [snrwb.sampleCollects]);

  const addSampleCollect = async (
    organization: Organization,
    dto: CreateUpdateSampleCollectDto,
  ) => {
    const createMethod = async () => {
      if ('id' in organization) {
        dto.organizationId = organization.id;
        return await snrwb.sampleCollects.createNew(dto);
      }
      return await snrwb.sampleCollects.createWithNewOrganization(
        dto,
        organization,
      );
    };

    try {
      const sampleCollect = await createMethod();
      setRedirectId(sampleCollect.id);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };

  useEffect(() => {
    let mounted = true;
    const safe = (set: () => void) => {
      if (mounted) {
        set();
      }
    };

    snrwb.sampleCollects
      .getPendingForUser()
      .then(collects => safe(() => setMySampleCollects(collects)));
    snrwb.sampleExams
      .getExamsWithoutApprovedResults()
      .then(exams => safe(() => setExamsWithoutResults(exams)));

    return () => {
      mounted = false;
    };
  }, [snrwb]);

  useEffect(() => {
    if (redirectId) {
      nav.sampleCollect(redirectId);
    }
  }, [redirectId, nav]);

  return (
    <Card>
      <TabsHistory activeKey="my" id="sampleExams" className="mb-3">
        <Tab
          eventKey="my"
          className="mx-3 mb-3"
          title={
            <>
              <Icon.CardChecklist /> Moje pobrania
            </>
          }
        >
          {mySampleCollects ? (
            <>
              <SampleCollectsList
                sampleCollects={mySampleCollects}
                onSelect={nav.sampleCollect}
                emptyGridMessage="Obecnie w tej jednostce organizacyjnej nie są prowadzone badania próbek."
                duringInspectionBasisId={duringInspectionBasisId || ''}
              />
              <AddSampleCollect onCompleted={addSampleCollect} />
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab
          eventKey="orders"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Nieprzekazane do publikacji
            </>
          }
        >
          <SampleExamsList
            sampleExams={examsWithoutResults}
            onSelect={nav.sampleExam}
            emptyGridMessage="Nie ma żadnych zleconych badań próbek nieprzekazanych do publikacji."
          />
        </Tab>
        <Tab
          eventKey="search"
          className="mx-3"
          title={
            <>
              <Icon.Search /> Wyszukiwanie
            </>
          }
        >
          <SampleExamSearch
            getData={snrwb.sampleExams.getAllByPortion}
            getCount={snrwb.sampleExams.getAllCount}
            onSelect={nav.sampleExam}
            defaultAdditionalFilters={{
              fileNumber: '',
              sysNumber: '',
              organizationalUnit: '',
              organization: OrganizationContext.newOrganization(),
              productType: '',
              collectDateFrom: null,
              collectDateTo: null,
            }}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
