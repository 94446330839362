/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportNegativeCharacteristic,
    ReportNegativeCharacteristicFromJSON,
    ReportNegativeCharacteristicFromJSONTyped,
    ReportNegativeCharacteristicToJSON,
    ReportNegativeJudgmentSampleDetails,
    ReportNegativeJudgmentSampleDetailsFromJSON,
    ReportNegativeJudgmentSampleDetailsFromJSONTyped,
    ReportNegativeJudgmentSampleDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportNegativeTestType
 */
export interface ReportNegativeTestType {
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeTestType
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeTestType
     */
    product: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportNegativeTestType
     */
    pprDateActivity: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportNegativeTestType
     */
    pprDateSigningProtocol: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeTestType
     */
    jorSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeTestType
     */
    pprFileNo: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeTestType
     */
    pprId: string;
    /**
     * 
     * @type {Array<ReportNegativeCharacteristic>}
     * @memberof ReportNegativeTestType
     */
    characteristics: Array<ReportNegativeCharacteristic>;
    /**
     * 
     * @type {Array<ReportNegativeJudgmentSampleDetails>}
     * @memberof ReportNegativeTestType
     */
    judgments: Array<ReportNegativeJudgmentSampleDetails>;
}

export function ReportNegativeTestTypeFromJSON(json: any): ReportNegativeTestType {
    return ReportNegativeTestTypeFromJSONTyped(json, false);
}

export function ReportNegativeTestTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNegativeTestType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityName': json['entityName'],
        'product': json['product'],
        'pprDateActivity': (new Date(json['pprDateActivity'])),
        'pprDateSigningProtocol': (new Date(json['pprDateSigningProtocol'])),
        'jorSymbol': json['jorSymbol'],
        'pprFileNo': json['pprFileNo'],
        'pprId': json['pprId'],
        'characteristics': ((json['characteristics'] as Array<any>).map(ReportNegativeCharacteristicFromJSON)),
        'judgments': ((json['judgments'] as Array<any>).map(ReportNegativeJudgmentSampleDetailsFromJSON)),
    };
}

export function ReportNegativeTestTypeToJSON(value?: ReportNegativeTestType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityName': value.entityName,
        'product': value.product,
        'pprDateActivity': (value.pprDateActivity.toISOString()),
        'pprDateSigningProtocol': (value.pprDateSigningProtocol.toISOString()),
        'jorSymbol': value.jorSymbol,
        'pprFileNo': value.pprFileNo,
        'pprId': value.pprId,
        'characteristics': ((value.characteristics as Array<any>).map(ReportNegativeCharacteristicToJSON)),
        'judgments': ((value.judgments as Array<any>).map(ReportNegativeJudgmentSampleDetailsToJSON)),
    };
}


