/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterDataExtended
 */
export interface FilterDataExtended {
    /**
     * 
     * @type {boolean}
     * @memberof FilterDataExtended
     */
    onlyCpr: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FilterDataExtended
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterDataExtended
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof FilterDataExtended
     */
    organizationalUnitId?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDataExtended
     */
    productDescription?: string;
}

export function FilterDataExtendedFromJSON(json: any): FilterDataExtended {
    return FilterDataExtendedFromJSONTyped(json, false);
}

export function FilterDataExtendedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDataExtended {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onlyCpr': json['onlyCpr'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : (new Date(json['dateFrom'])),
        'dateTo': !exists(json, 'dateTo') ? undefined : (new Date(json['dateTo'])),
        'organizationalUnitId': !exists(json, 'organizationalUnitId') ? undefined : json['organizationalUnitId'],
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
    };
}

export function FilterDataExtendedToJSON(value?: FilterDataExtended | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onlyCpr': value.onlyCpr,
        'dateFrom': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString()),
        'dateTo': value.dateTo === undefined ? undefined : (value.dateTo.toISOString()),
        'organizationalUnitId': value.organizationalUnitId,
        'productDescription': value.productDescription,
    };
}


